"use client";

import { SessionProvider } from 'next-auth/react';
import ActionModal from '../../../../components/ActionModal';
import useActionModal from './hooks/confirm-modal';

const ActionModalContainer = () => {
    const { isOpen, config, hide } = useActionModal();

    return <SessionProvider>
        <ActionModal />
        </SessionProvider>;
}

export default ActionModalContainer;