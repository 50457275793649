import { ReactNode } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface ActionButton {
  label: string;
  onClick: () => void;
  tooltip?: string;
}
export interface ModalConfig {
  title: string;
  description?: string;
  component?: ReactNode;
  buttons?: ActionButton[];
}

type State = {
  isOpen: boolean;
  config?: ModalConfig;
};

type Actions = {
  toggleModal: (isOpen: boolean, config?: ModalConfig) => void;
};

export const useActionModalStore = create(
  immer<State & Actions>((set) => ({
    isOpen: false,

    toggleModal: (isOpen: boolean, config?: ModalConfig) =>
      set((state) => {
        if (config) {
          state.config = config;
        }
        state.isOpen = isOpen;
      })
  }))
);