"use client";
import ActionModalContainer from "@/shared/components/action-modal";
import BreakpointIndicator from "@/shared/components/breakpoint-indicator";
import { ThemeProvider } from "@/shared/components/shadcn/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient()

interface AppWrapperProps {
  children: React.ReactNode;
}
const AppWrapper = ({ children }: AppWrapperProps) => {
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="system"
      enableSystem
      disableTransitionOnChange
    >
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
      <ActionModalContainer />
      <ToastContainer />
      {
        (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") && (
          <BreakpointIndicator />
        )
      }
    </ThemeProvider>
  )
}

export default AppWrapper;