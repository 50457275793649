'use client';
import { Button } from '@/shared/components/shadcn/ui/button';
import { cn } from '@/shared/utils/';
import { useSession } from 'next-auth/react';
import React from 'react';
import styles from './PaymentPlanModal.css';

interface Plan {
  name: string;
  price: number;
  credits: number;
  link: string;
  description: string;
  features: string[];
  highlighted?: boolean;
}

const plans: Plan[] = [
  {
    name: 'Basic Plan',
    price: 10,
    credits: 10000,
    link: 'https://buy.stripe.com/00g3ecb3R7IWgW45kl?client_reference_id=',
    description: 'Enough credits to get started',
    features: [
      '10.000 credits / month',
      'Rollover to next month!',
    ],
  },
  {
    name: 'Pro Plan',
    price: 20,
    credits: 25000,
    link: 'https://buy.stripe.com/4gwcOM1th3sGfS0cMO?client_reference_id=',
    description: 'Finally start your dream project',
    features: [
      '25.000 credits / month',
      'Rollover to next month!',
    ],
    highlighted: true,
  },
];

const CheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={cn('w-6 h-6', className)}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const PaymentPlanModal: React.FC = () => {
  const { data: session } = useSession();
  const userId = session?.user?.id || '';

  return (
    <div className="max-h-[80vh] overflow-y-auto px-4 py-6">
      <h2 className="text-black dark:text-white text-2xl font-semibold mb-6 text-center">
        Choose a plan to continue
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={cn(
              'ring-1 rounded-2xl p-6',
              'bg-white dark:bg-gray-900/80 ring-gray-300/70 dark:ring-gray-700',
              plan.highlighted ? styles.fancyGlassContrast : ''
            )}
          >
            <h3 className="text-black dark:text-white text-xl font-bold tracking-tight">
              {plan.name}
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-2 text-sm leading-6">
              {plan.description}
            </p>
            <p className="mt-4 flex items-baseline gap-x-1">
              <span className="text-black dark:text-white text-3xl font-bold tracking-tight">
                ${plan.price}
              </span>
              <span className="text-gray-600 dark:text-gray-400 text-sm font-semibold leading-6">
                /month
              </span>
            </p>
            <a
              href={`${plan.link}${userId}`}
              className="flex mt-4 shadow-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                size="sm"
                className={cn(
                  'w-full text-black dark:text-white',
                  plan.highlighted
                    ? 'bg-pink-300 hover:bg-pink-400 dark:bg-pink-600 dark:hover:bg-pink-700'
                    : 'bg-gray-100 dark:bg-gray-600'
                )}
                variant={plan.highlighted ? 'default' : 'outline'}
              >
                Select Plan
              </Button>
            </a>
            <ul className="text-gray-700 dark:text-gray-400 mt-4 space-y-2 text-sm leading-6">
              {plan.features.map((feature) => (
                <li key={feature} className="flex gap-x-2">
                  <CheckIcon
                    className={cn(
                      'h-5 w-4 flex-none',
                      plan.highlighted ? 'text-pink-500' : 'text-gray-500'
                    )}
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentPlanModal;