import React from 'react';

const BreakpointIndicator = () => {
    return (
        <div className="absolute left-4 bottom-4 z-50 opacity-90 p-4 bg-teal-400">
            <div className="block sm:hidden">sm</div>
            <div className="hidden sm:block md:hidden">md</div>
            <div className="hidden md:block lg:hidden">lg</div>
            <div className="hidden lg:block xl:hidden">xl</div>
            <div className="hidden xl:block">2xl</div>
        </div>
    );
};

export default BreakpointIndicator;
