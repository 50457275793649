import { ModalConfig, useActionModalStore } from "@/shared/store/action-modal";
import React from 'react';
import PaymentPlanModal from "../../../../../components/PaymentPlanModal";

const useActionModal = () => {
    const { isOpen, toggleModal, config } = useActionModalStore();

    const show = (config: ModalConfig) => {
        toggleModal(true, config);
    }

    const hide = () => {
        toggleModal(false);
    }

    const showPaymentPlanModal = () => {
        show({
            title: "Out of Credits",
            component: React.createElement(PaymentPlanModal)
        });
    }

    return {
        isOpen,
        config,
        show,
        hide,
        showPaymentPlanModal
    }
}

export default useActionModal;