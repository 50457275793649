import { Button } from '@/shared/components/shadcn/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/shared/components/shadcn/ui/dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/components/shadcn/ui/tooltip';
import { useActionModalStore } from '@/shared/store/action-modal';
import { useSession } from 'next-auth/react';
import React from 'react';


const ActionModal: React.FC = () => {
  const { isOpen, config, toggleModal } = useActionModalStore();
  const { data: session } = useSession();

  if (!isOpen || !config) return null;

  const hide = () => toggleModal(false);

  return (
    <Dialog open={isOpen} onOpenChange={hide}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-hidden flex flex-col">
        <DialogHeader className="flex-shrink-0">
          <DialogTitle>{config.title}</DialogTitle>
          {config.description && (
            <DialogDescription dangerouslySetInnerHTML={{ __html: config.description }} />
          )}
        </DialogHeader>
        <div className="flex-grow overflow-y-auto">
          {config.component ? (
            React.cloneElement(config.component as React.ReactElement, { session })
          ) : (
            <div className="flex items-center justify-center gap-2">
              {config.buttons && config.buttons.map((button, index) => (
                <TooltipProvider key={index} delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button onClick={() => {
                        button.onClick();
                        hide();
                      }}>
                        {button.label}
                      </Button>
                    </TooltipTrigger>
                    {button.tooltip && (
                      <TooltipContent>
                        <p>{button.tooltip}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ActionModal;